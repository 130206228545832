<template>
  <div>
    <b-form-group label="فلترة حسب الحالة" class="filter-radius">
      <b-form-radio v-model="filterSelected" name="some-radios" value="-1"
        >الكل</b-form-radio
      >
      <b-form-radio v-model="filterSelected" name="some-radios" value="0"
        >غير المجاب فقط</b-form-radio
      >
      <b-form-radio v-model="filterSelected" name="some-radios" value="1"
        >المجاب فقط</b-form-radio
      >
    </b-form-group>
    <b-form-checkbox-group v-model="selectedInbox">
      <b-card no-body class="mb-1">
        <b-card-header class="p-0 justify-content-end">
          <small>حذف المحدد</small>
          <b-button
            size="sm"
            variant="flat-secondary"
            class="btn-icon rounded-circle mr-1"
            style="padding: 0.5rem 10px !important"
            :disabled="!selectedInbox.length"
            @click="submitDeleteMultiInbox"
            v-b-tooltip.hover.v-danger
            title="حذف متعدد"
          >
            <unicon name="trash-alt" width="18"></unicon>
          </b-button>
        </b-card-header>
      </b-card>
      <div v-for="(inbox, index) in activeInboxList" :key="index">
        <b-card
          v-if="
            filterSelected == -1 ||
            (filterSelected == 1 && inbox.responseDate) ||
            (filterSelected == 0 && !inbox.responseDate)
          "
          no-body
          class="mb-1"
        >
          <b-card-header class="p-1">
            <div class="d-flex">
            <b-form-checkbox class="mr-25" :value="inbox.id"></b-form-checkbox>
              <span>المرسل: {{ inbox.name }}</span>
            </div>
            <span>العنوان: {{ inbox.title }}</span>
            <span
              >التاريخ: {{ new Date(inbox.sendDate).toLocaleDateString("en-GB") }}</span
            >
            <div class="d-flex align-items-center">
              <b-badge
                class="ml-auto mr-50 p-50 rounded-circle h-100"
                variant="success"
                v-if="inbox.response"
                v-b-tooltip.hover.v-success
                title="تم الرد"
                ><unicon name="check" fill="#fff"
              /></b-badge>

              <b-button
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                style="padding: 0.5rem 10px !important"
                :to="'/admin/main/inbox/details/' + inbox.id"
              >
                <unicon name="ellipsis-v" width="18"></unicon>
              </b-button>
            </div>
          </b-card-header>
        </b-card>
      </div>
    </b-form-checkbox-group>
    <b-col cols="12" class="d-flex justify-content-center">
      <b-pagination
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        :value="inboxFirstPage"
        :total-rows="filteredInboxList.length"
        :per-page="inboxPageLength"
        @input="(value) => updateInboxPagination(value)"
      >
        <template #prev-text>
          <unicon width="20" name="angle-right" fill="#A41F02" />
        </template>
        <template #next-text>
          <unicon width="20" name="angle-left" fill="#A41F02" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { VBTooltip } from "bootstrap-vue";
import Swal from "sweetalert2";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapGetters([
      "activeInboxList",
      "filteredInboxList",
      "inboxFirstPage",
      "inboxPageLength",
    ]),
  },
  data: () => ({
    selectedInbox: [],
    filterSelected: -1,
  }),
  created() {
    this.getInbox();
  },
  methods: {
    ...mapActions(["updateInboxPagination", "getInbox", "deleteMultiInbox"]),
    submitDeleteMultiInbox() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف البريد المحدد؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            this.deleteMultiInbox(this.selectedInbox);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-radius .custom-radio {
  display: inline-block;
  &:not(:first-of-type) {
    margin-left: 0.5rem;
  }
}
</style>
